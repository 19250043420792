<template>
  <!-- 调岗申请列表 -->
  <div class="page">
    <div class="title" style="margin: 20px 0">员工调岗</div>

    <div class="filter_box" style="margin-bottom: 20px">
      <div class="filter_search">
        <el-select
          v-model="selectValue"
          placeholder="请选择"
          class="filter_select"
          @change="getSelValue"
        >
          <el-option
            v-for="item in selectArr"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input
          class="filter_search_input"
          placeholder="请选择状态"
          v-model="keyword"
          clearable
        >
        </el-input>
        <el-select
          style="margin-left: 16px"
          v-model="status"
          placeholder="请选择"
          filterable
          clearable
          class="filter_select"
        >
          <el-option
            v-for="item in statusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div class="custom_button" @click="gettrabList(1)">搜索</div>

        <div class="custom_button warning" @click="exportRoster">
          导出调岗信息
        </div>
      </div>
    </div>

    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column align="center" type="selection" width="55">
      </el-table-column>
      <el-table-column align="center" prop="staff_id" label="ID" width="120">
      </el-table-column>
      <el-table-column
        align="center"
        prop="real_name"
        label="员工姓名"
        width="120"
      >
      </el-table-column>
      <el-table-column align="center" prop="origin_duty_name" label="原部门">
      </el-table-column>
      <el-table-column align="center" prop="origin_station_name" label="原岗位">
      </el-table-column>
      <el-table-column align="center" prop="origin_salary_base" label="原工资">
      </el-table-column>
      <el-table-column align="center" prop="new_duty_name" label="调岗后部门">
      </el-table-column>
      <el-table-column
        align="center"
        prop="new_station_name"
        label="调岗后岗位"
      >
      </el-table-column>
      <el-table-column align="center" prop="new_salary_base" label="调岗后工资">
      </el-table-column>
      <el-table-column
        align="center"
        prop="new_attendance_standard"
        label="调岗后出勤"
      >
      </el-table-column>
      <el-table-column align="center" label="生效日期" prop="transfer_date">
      </el-table-column>
      <el-table-column align="center" prop="status_value" label="调岗状态">
      </el-table-column>
      <el-table-column
        align="center"
        label="操作"
        fixed="right"
        min-width="120"
      >
        <template slot-scope="scope">
          <div class="operation">
            <div @click="showMsg(scope.row)">查看</div>
            |
            <div @click="revoke(scope.row)">撤销</div>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      layout="prev, pager, next"
      :total="page.total"
      :page-size="page.size"
      :current-page.sync="page.page"
      @current-change="gettrabList($event)"
      ref="pagination"
    >
    </el-pagination>

    <!-- 查看弹框 -->
    <el-dialog
      :title="menberDatail.real_name + '员工详情'"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <el-table
        class="table_center"
        :data="tableList"
        border
        style="width: 100%"
      >
        <el-table-column prop="title" label="" width="180"> </el-table-column>
        <el-table-column prop="oldItem" label="原信息" width="180">
        </el-table-column>
        <el-table-column prop="newItem" label="调岗后"> </el-table-column>
      </el-table>
      <div class="tableList_explain">
        <div class="tableList_creatTime">
          创建时间： {{ menberDatail.create_name }} 在
          {{ menberDatail.update_time }}进行创建
        </div>
        <div class="tableList_determineTime">
          <span
            v-if="menberDatail.confirm_time && menberDatail.confirm_time != '-'"
            >确认时间： 员工在{{ menberDatail.confirm_time }} 确认</span
          >
          <span v-else>确认时间： 员工暂未确认</span>
        </div>
        <div class="tableList_effectiveTime">
          生效时间：{{ menberDatail.transfer_date }}生效
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      //下拉框
      selectArr: [
        {
          label: "员工ID",
          value: 1,
        },
        {
          label: "员工姓名",
          value: 2,
        },
        {
          label: "员工手机号码",
          value: 3,
        },
      ],
      statusList: [
        {
          label: "待确认",
          value: 0,
        },
        {
          label: "待生效",
          value: 1,
        },
        {
          label: "已生效",
          value: 2,
        },
        {
          label: "已取消",
          value: 3,
        },
      ],
      // 选择状态
      status: "",
      // 选择搜索选项
      selectValue: 1,
      keyword: "", // 搜索的值
      placeholderWord: "请输入员工ID",
      dialogVisible: false, // 控制弹框
      tableList: [],
      tableData: [],
      multipleSelection: [],
      // 分页参数
      page: {
        size: 10,
        page: 1,
        total: 0,
      },
      //记录信息
      menberDatail: {},
    };
  },
  created() {
    this.gettrabList();
  },

  methods: {
    ...mapActions("postTransfer", [
      "getTransferList",
      "getTransferDetail",
      "revokeTransfer",
      "delTransfer",
      "exportTransfer",
    ]),
    // 下来选择搜索关键词
    getSelValue(e) {
      console.log(e);
      this.placeholderWord = `请输入${this.selectArr[e - 1].label}`;
      this.keyword = "";
    },
    // 列表
    gettrabList(event = 1) {
      console.log(this.selectValue);
      let { size } = this.page;
      let params = { page: event, size };

      if (this.keyword.trim().length > 0) {
        switch (this.selectValue) {
          case 1:
            params.staff_id = this.keyword;
            break;
          case 2:
            params.real_name = this.keyword;
            break;
          case 3:
            params.mobile = this.keyword;
            break;
          default:
            break;
        }
      }
      if (this.status !== "") {
        params.status = this.status;
      }

      let loading = this.$loading();
      console.log(params);
      this.getTransferList(params)
        .then((res) => {
          console.log(res, "调岗列表");
          if (res.data.code == 200) {
            let { total, list } = res.data.data;
            this.tableData = list;
            this.page.total = total;
          }
          loading.close();
        })
        .catch((err) => {
          console.log(err);
          loading.close();
        });
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 获取分页数据
    loadData(func) {
      console.log(func);
    },
    // 查看信息
    showMsg(scope) {
      console.log(scope);
      let { real_name, id } = scope || {};
      this.getTransferDetail({ id })
        .then((res) => {
          // console.log(res, "查看");
          this.dialogVisible = true;
          if (res.data.code == 200) {
            let { update_time, transfer_date, create_name, confirm_time } =
              res.data.data;
            let menberData = res.data.data;
            console.log(JSON.parse(JSON.stringify(menberData)));
            this.menberDatail = {
              real_name,
              update_time,
              transfer_date,
              create_name,
              confirm_time,
            };
            this.tableList = [
              {
                title: "公司/部门",
                oldItem: menberData.origin_department_name,
                newItem: menberData.new_department_name,
              },
              {
                title: "岗位",
                oldItem: menberData.origin_station_name,
                newItem: menberData.new_station_name,
              },
              {
                title: "月薪",
                oldItem: menberData.origin_salary_base,
                newItem: menberData.new_salary_base,
              },
              {
                title: "出勤标准",
                oldItem: menberData.origin_attendance_standard,
                newItem: menberData.new_attendance_standard,
              },
              {
                title: "正常工作时间公司",
                oldItem: menberData.origin_normal_work_salary,
                newItem: menberData.new_normal_work_salary,
              },
            ];
          }
        })
        .catch((err) => {
          console.log(err);

          this.$alert(err.message, "提示", {
            confirmButtonText: "确定",
            type: "error",
          });
        });
    },
    // 撤销申请
    revoke(scope) {
      console.log(scope, "撤销");
      this.$confirm("是否撤销?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          console.log(res);
          this.removeList(scope);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async removeList(scope) {
      let currentPage = this.$refs.pagination.currentPage;
      // 撤销调岗
      await this.revokeTransfer({ id: scope.id })
        .then((res) => {
          console.log(res);
          this.$alert(res.data.message, "提示", {
            confirmButtonText: "确定",
            type: "success ",
          });
        })
        .catch((err) => {
          console.log(err);
          this.$alert(err.message, "提示", {
            confirmButtonText: "确定",
            type: "error",
          });
        });
      // 删除记录
      await this.delTransfer({ id: scope.id })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
      // 重新获取列表
      await this.gettrabList(currentPage);
    },

    // 搜索
    toSearch() {
      console.log(111);
    },
    // 导出调岗信息
    exportRoster() {
      // console.log(this.multipleSelection);
      if (this.multipleSelection.length == 0) {
        this.$message({
          showClose: true,
          message: "请选择员工",
          type: "warning",
        });
        return;
      }
      let idList = this.multipleSelection.reduce((prev, next) => {
        console.log(prev, next.id);
        next.id && prev.push(next.id);
        return prev;
      }, []);
      console.log("导出花名册", idList);

      let loading = this.$loading();
      this.exportTransfer({
        url: `web/staff/exportTransfer?id=${idList.join(",")}`,
        name: "员工信息表",
      })
        .then((res) => {
          console.log(res);
          this.$alert(res.data.message, "导出调岗员工表", {
            confirmButtonText: "确定",
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          loading.close();
        });
    },
  },
};
</script>

<style scoped>
.filter_search_input {
  width: 20%;
  margin-right: 20px;
}
.custom_button {
  display: inline-block;
  margin-left: 20px;
}
.warning {
  background-color: #f39c12;
}
.operation > div {
  display: inline-block;
  color: rgb(24, 144, 255);
}
.operation > div:hover {
  cursor: pointer;
}

.table_center ::v-deep .el-table_2_column_14,
.table_center ::v-deep .el-table_2_column_15 {
  background-color: rgb(242, 242, 242);
}
.table_center ::v-deep .is-leaf {
  background-color: #efb252;
  text-align: center;
  color: #333;
}
.table_center ::v-deep .cell {
  text-align: center;
}
.filter_select {
  width: 145px;
}
</style>